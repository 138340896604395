import React from 'react'
import { graphql } from "gatsby"
import BackgroundImg from 'gatsby-background-image'
import Img from 'gatsby-image'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ExitToIcon from '@material-ui/icons/ExitToApp'
import Fab from '@material-ui/core/Fab';
import ArrowFowardIosIcon from '@material-ui/icons/ArrowForwardIos';
import HotelIcon from '@material-ui/icons/Hotel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WalkIcon from '@material-ui/icons/DirectionsWalk';
import TrainIcon from '@material-ui/icons/Train';
import CarIcon from '@material-ui/icons/DirectionsCar';
import BikeIcon from '@material-ui/icons/DirectionsBike';
import Badge from '@material-ui/core/Badge'
import Chip from '@material-ui/core/Chip'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import Layout from '../../../../components/layout'
import SEO from '../../../../components/seo'
import Contents from '../../../../components/contents'
import Paragraphs from '../../../../components/paragraph'
import Heading from '../../../../components/heading'
import HeroBox from '../../../../components/herobox'

import crest from '../../../../assets/crest/takeda/white/48.png'

const useStyles = makeStyles(theme => ({
    root: {

    },
    root2: {
        padding: '0 30px',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    media: {
    height: 0,
    paddingTop: '56.25%',
    // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: "#48bd96",
    '& > img': {
        marginBottom: '0',
    },
  },
   crest: {

    },
  clip: {
        backgroundColor: "#48bd96",
        color: "#ffffff",
        '&:hover': {
            backgroundColor: "#ffe066",
            color: "#000000",
        },
    },
    clipAvatar: {
        backgroundColor: "#30a679",
        color: "#ffffff",
    },
    rootContainer: {
        padding: 0,
    },
    galleryContainer: {
        padding: '15px',
    },
    hero: {
        padding: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '80vh',
        textAlign: 'center',
        color: '#ffffff',
    },
    heading1: {
        backgroundColor: 'rgba(72,189,152,.9)',
        padding: '20px;'
    },
    paragraphs: {
        paddingTop: '30px;'
    },
    paragraph: {
        backgroundColor: 'rgba(0,0,0,.5)',
    },
    button: {
        textAlign: 'right',
        marginBottom: '1.45rem',
        paddingRight: '30px',
    },
    fab: {
        backgroundColor: "#48bd96",
        color: "#ffe066",
        '&:hover': {
            backgroundColor: "#ffe066",
            color: "#48bd96",
        }
    },
    contentRoot: {
        padding: '0 30px',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    imageContainer: {
        margin: '30px 15px',
    },
    gridContainer: {
        padding: '0px',
        margin: '30px 15px',
    },
    heroImageSection: {
        padding: '0px',
        margin: '0px',
    },
}));

const IsawaonsenStationTakeda24GeneralsPage = ({ data }) => {
    const classes = useStyles();
    const language = 'ja';
    const slug = 'station/isawaonsen/takeda-24-generals';
    const pageTitle = '武田二十四将の宿';
    const takedaShingen1 = '武田信玄';
    const takedaShingen2 = '武田信玄はこんな武将';
    const takedaShingen3 = '現代にも通用する武田信玄の魅力';
    const pageSubtitle = '武田二十四将';
    const notChild = false;
    const child = true;

    return (
        <Layout language={language} slug={slug}>
            <SEO title={pageTitle} />
            {/* <BackgroundImg
                Tag="section"
                fluid={data.hero.childImageSharp.fluid}
                backgourndColor={`#48bd98`}
                style={{
                    // minHeight: '80vh',
                    minHeight: '550px',
                    backgroundSize: 'auto',
                }}
            ></BackgroundImg> */}
            {/* <HeroBox
                image={data.hero.childImageSharp.fluid}
                stationName=""
                descriptions=""
                language={language}
            /> */}
            <section className={classes.heroImageSection}>
                <Img objectFit="contain" fluid={data.hero.childImageSharp.fluid} />
            </section>
            <Container component="section" className={classes.rootContainer}>
                <Box component="article">
                    <section>
                        <Heading title={pageTitle} isChild={notChild} />
                        <div className={classes.contentRoot}>
                            <p>2022年までの期間限定！</p>
                            <p>武田信玄の生誕500年を祝う、記念イベント。</p>
                            <div className={classes.imageContainer}><Img objectFit="contain" fluid={data.flags.childImageSharp.fluid} /></div>
                            <p>信玄を支えた24人の武将がテーマ。</p>
                            <p>石和温泉の旅館、ホテルの中から、名乗りを上げた宿泊施設が、武将を一人ずつ担当。</p>
                            <p>戦国時代にタイムスリップしたような、特別な体験ができる。</p>
                        </div>
                    </section>
                    <section>
                        <Heading title={takedaShingen1} isChild={notChild} />
                        {/* <div className={classes.gridContainer}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Img objectFit="contain" fixed={data.shingen.childImageSharp.fixed} />
                                </Grid>
                            </Grid>
                        </div> */}
                        <div className={classes.imageContainer}><Img objectFit="contain" fluid={data.shingen.childImageSharp.fluid} /></div>
                    </section>
                    <section>
                        <Heading title={takedaShingen2} isChild={child} />
                        <div className={classes.contentRoot}>
                            <p>最強戦国武将との声も多い。</p>
                            <p>生涯戦績72戦49勝3敗20引き分け。</p>
                            <p></p>
                            <p>徳川家康に勝利。</p>
                            <p>たった2000人で、5万人と戦って勝った。</p>
                        </div>
                    </section>
                    <section>
                        <Heading title={takedaShingen3} isChild={child} />
                        <div className={classes.contentRoot}>
                            <dl>
                                <dt>リーダーシップ</dt>
                                <dd>独断で決めず、家臣と力を合わせて問題解決する。</dd>
                                <dt>情報収集能力</dt>
                                <dd>スパイを全国に配置、その情報から作戦を練る。</dd>
                                <dt>人材登用力</dt>
                                <dd>能力のある者は、農民でも家臣に取り立てる。</dd>
                                <dt>部下の力を引き出す</dt>
                                <dd>軍議の席では、全員に発言権を持たせる。</dd>
                            </dl>
                        </div>
                    </section>
                    <section>
                        <Heading title={pageSubtitle} isChild={notChild} />
                        <div className={classes.contentRoot}>
                            <p>武田信玄のブレインとして、彼を支えた武将たち。</p>
                            <p>「戦国最強家臣団」と、その名をとどろかせていた。</p>
                        </div>
                        <div className={classes.gridContainer}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Card className={classes.root}>
                                        <CardHeader
                                            avatar={<Avatar aria-label="takeda family crest" className={classes.avatar} src={crest} alt="高坂弾正忠昌信" />}
                                            // action={
                                            //     <IconButton aria-label="settings">
                                            //         <MoreVertIcon />
                                            //     </IconButton>
                                            // }
                                            title="高坂弾正忠昌信"
                                            subheader="こうさかだんじょうのじょうまさのぶ"
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image={data.kousaka.childImageSharp.fixed.src}
                                            objectFix="cover"
                                            title="高坂弾正忠昌信"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <p>信玄との恋物語でも知られる、武田軍一の美男子。</p>
                                                <p>戦の先陣をきる激しさの一方、冷静で頭脳明晰。敵に侮られず撤退するのが得意で「逃げ弾正」のあだ名があった。</p>
                                                <p>信玄亡き後も武田家に仕え、二十四将の中でも「四天王」とよばれる名将の一人。</p>
                                            </Typography>
                                        </CardContent>
                                        <CardActions disableSpacing>
                                            <Link href="/hotel/yamanashi/kikori/"><Chip
                                                avatar={<Avatar className={classes.clipAvatar}><ChevronRightIcon /></Avatar>}
                                                // avatar={<Avatar className={classes.clipAvatar}><HotelIcon /></Avatar>}
                                                label="旅館きこり"
                                                clickable
                                                className={classes.clip}
                                                // variant="outlined"
                                            /></Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Card className={classes.root}>
                                        <CardHeader
                                            avatar={<Avatar aria-label="takeda family crest" className={classes.avatar} src={crest} alt="小幡山城守虎盛" />}
                                            // action={
                                            //     <IconButton aria-label="settings">
                                            //         <MoreVertIcon />
                                            //     </IconButton>
                                            // }
                                            title="小幡山城守虎盛"
                                            subheader="おばたやましろのかみとらもり"
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image={data.obata.childImageSharp.fixed.src}
                                            objectFix="cover"
                                            title="小幡山城守虎盛"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <p>「鬼虎」と呼ばれた勇将。槍の達人。</p>
                                                <p>ずば抜けた戦歴を見込まれて、父の信虎と信玄の二代に使えた。</p>
                                                <p>戦の時は、常に自らが最前線に立つスタイルで、部下を鼓舞した。</p>
                                            </Typography>
                                        </CardContent>
                                        <CardActions disableSpacing>
                                            <Link href="/hotel/yamanashi/mine/"><Chip
                                                avatar={<Avatar className={classes.clipAvatar}><ChevronRightIcon /></Avatar>}
                                                // avatar={<Avatar className={classes.clipAvatar}><HotelIcon /></Avatar>}
                                                label="和風旅館峯"
                                                clickable
                                                className={classes.clip}
                                                // variant="outlined"
                                            /></Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Card className={classes.root}>
                                        <CardHeader
                                            avatar={<Avatar aria-label="takeda family crest" className={classes.avatar} src={crest} alt="甘利備前守虎泰" />}
                                            // action={
                                            //     <IconButton aria-label="settings">
                                            //         <MoreVertIcon />
                                            //     </IconButton>
                                            // }
                                            title="甘利備前守虎泰"
                                            subheader="あまりびぜんのかみとらやす"
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image={data.amari.childImageSharp.fixed.src}
                                            objectFix="cover"
                                            title="甘利備前守虎泰"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <p>二十四将の中でも、板垣信方と並ぶ2トップ。</p>
                                                <p>信虎と信玄二代の重要な作戦のほとんどに参加。</p>
                                                <p>甘利隊が出撃すると、敵が戦わずに逃げ出したと、文献にも残っている勇将。</p>
                                            </Typography>
                                        </CardContent>
                                        <CardActions disableSpacing>
                                            <Link href="/hotel/yamanashi/ukai/"><Chip
                                                avatar={<Avatar className={classes.clipAvatar}><ChevronRightIcon /></Avatar>}
                                                // avatar={<Avatar className={classes.clipAvatar}><HotelIcon /></Avatar>}
                                                label="ホテルうかい"
                                                clickable
                                                className={classes.clip}
                                                // variant="outlined"
                                            /></Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Card className={classes.root}>
                                        <CardHeader
                                            avatar={<Avatar aria-label="takeda family crest" className={classes.avatar} src={crest} alt="土屋右衛門尉昌続" />}
                                            // action={
                                            //     <IconButton aria-label="settings">
                                            //         <MoreVertIcon />
                                            //     </IconButton>
                                            // }
                                            title="土屋右衛門尉昌続"
                                            subheader="つちやうえもんのじょうまさつぐ"
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image={data.tsuchiya.childImageSharp.fixed.src}
                                            objectFix="cover"
                                            title="土屋右衛門尉昌続"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <p>激しい戦の中でも、本陣の信玄を守って動じない、側近中の側近。</p>
                                                <p>17歳で初陣。わずか5年で大将に抜擢された。</p>
                                                <p>文武両道を地でいく男。</p>
                                            </Typography>
                                        </CardContent>
                                        <CardActions disableSpacing>
                                            <Link href="/hotel/yamanashi/hatta/"><Chip
                                                avatar={<Avatar className={classes.clipAvatar}><ChevronRightIcon /></Avatar>}
                                                // avatar={<Avatar className={classes.clipAvatar}><HotelIcon /></Avatar>}
                                                label="ホテル八田"
                                                clickable
                                                className={classes.clip}
                                                // variant="outlined"
                                            /></Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Card className={classes.root}>
                                        <CardHeader
                                            avatar={<Avatar aria-label="takeda family crest" className={classes.avatar} src={crest} alt="馬場美濃守信春" />}
                                            // action={
                                            //     <IconButton aria-label="settings">
                                            //         <MoreVertIcon />
                                            //     </IconButton>
                                            // }
                                            title="馬場美濃守信春"
                                            subheader="ばばみののかみのぶはる"
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image={data.baba.childImageSharp.fixed.src}
                                            objectFix="cover"
                                            title="馬場美濃守信春"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <p>戦歴40年、70回におよぶ合戦で、傷を負ったことがない戦上手。</p>
                                                <p>不死身の武将と讃えられた。</p>
                                                <p>信虎、信玄、勝頼と三代に仕えた忠義者。</p>
                                            </Typography>
                                        </CardContent>
                                        <CardActions disableSpacing>
                                            <Link href="/hotel/yamanashi/kanpoisawa/"><Chip
                                                avatar={<Avatar className={classes.clipAvatar}><ChevronRightIcon /></Avatar>}
                                                // avatar={<Avatar className={classes.clipAvatar}><HotelIcon /></Avatar>}
                                                label="かんぽの宿石和"
                                                clickable
                                                className={classes.clip}
                                                // variant="outlined"
                                            /></Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Card className={classes.root}>
                                        <CardHeader
                                            avatar={<Avatar aria-label="takeda family crest" className={classes.avatar} src={crest} alt="今福浄閑斎" />}
                                            // action={
                                            //     <IconButton aria-label="settings">
                                            //         <MoreVertIcon />
                                            //     </IconButton>
                                            // }
                                            title="今福浄閑斎"
                                            subheader="いまふくじょうかんさい"
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image={data.imafuku.childImageSharp.fixed.src}
                                            objectFix="cover"
                                            title="今福浄閑斎"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <p>戦国最強といわれた武田家の、譜代家老。</p>
                                                <p>文武、特に「文」に優れ、事務方を担っていた。</p>
                                                <p>彼が守る久能山城は、堅い守りで「難攻不落の山城」と呼ばれた。</p>
                                            </Typography>
                                        </CardContent>
                                        <CardActions disableSpacing>
                                            <Link href="/hotel/yamanashi/heian/"><Chip
                                                avatar={<Avatar className={classes.clipAvatar}><ChevronRightIcon /></Avatar>}
                                                // avatar={<Avatar className={classes.clipAvatar}><HotelIcon /></Avatar>}
                                                label="ホテル平安"
                                                clickable
                                                className={classes.clip}
                                                // variant="outlined"
                                            /></Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                    </section>
                </Box>
            </Container>
        </Layout>
        // <pre>{JSON.stringify(data, null, 4)}</pre>
    )
    // return <pre>{JSON.stringify(data, null, 4)}</pre>
}

export const query = graphql`
  {
    hero:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/hero.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    flags:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/flags.png"}) {
      childImageSharp {
        fluid(maxWidth: 1000, pngQuality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    panel:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/panel.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 1000, maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shingen:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/takeda-shingen.png"}) {
      childImageSharp {
        fluid(maxWidth: 1000, pngQuality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kousaka:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/kousaka-masanobu2.jpg"}) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    amari:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/amari.jpg"}) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    baba:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/baba.jpg"}) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    imafuku:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/imafuku.jpg"}) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    obata:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/obata.jpg"}) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    tsuchiya:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/tsuchiya.jpg"}) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default IsawaonsenStationTakeda24GeneralsPage